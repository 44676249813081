export const menuItems = [    
    {
        id: 132,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 3,
        label: "menuitems.dashboards.text",
        link: "/",
        icon: "bx-home-circle"
    },
    // {
    //     id: 130,
    //     label: "menuitems.managerlist.text",
    //     link: "/user/manager-list",
    //     icon: "bx-user"
    // },
    {
        id: 129,
        label: "menuitems.userlist.text",
        link: "/user/user-list",
        icon: "bx bx-group"
    },
    {
        id: 134,
        label: "menuitems.add-brand-manager.text",
        link: "/user/add-file-manager",
        icon: "bx bx-user-x"
    }, 
    // {
    //     id: 131,
    //     label: "menuitems.filemanager.text",
    //     link: "/user/file-manager",
    //     icon: "bx-file"
    // },    
    {
        id: 132,
        label: "menuitems.category.text",
        link: "/category/category-list",
        icon: "bx-file"
    },    
    {
        id: 133,
        label: "menuitems.ecommerce.list.products",
        link: "/product/product-list",
        icon: "bx bx-box"
    }
];